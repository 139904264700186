// frontend/src/components/Header.tsx
import React, { useState, useEffect, useRef } from 'react';
import useAuth from '../hooks/useAuth';
import ashleyLogo from '../assets/AshleyAI.png';
import { getInitials } from '../utils/userUtils';
import { MdNotifications, MdNotificationsActive, MdArticle, MdHelpOutline } from 'react-icons/md';
import HelpPage from './HelpPage';

interface Notification {
  id: any;
  title: string;
  createdAt: string;
  content: string;
  unread: boolean;
}

const Header: React.FC = () => {
  const { user, token, isAuthenticated } = useAuth();
  const userName = user?.name || 'User';
  const userInitials = getInitials(userName);
  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [isPanelVisible, setIsPanelVisible] = useState(false);
  const [showHelpPage, setShowHelpPage] = useState(false);
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState<Notification | null>(null);
  const panelRef = useRef<HTMLDivElement>(null);
  const helpPageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        // Replace with your API call to fetch notifications
        const response = await fetch(`/api/articles/${user?.localAccountId}`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });

        const data = await response.json();

        if(data) {
          setNotifications(data);
          const unreadExists = data.some((notification: Notification) => notification.unread);
          setHasUnreadNotifications(unreadExists);
        }
      } catch (error) {
        console.error(error);
      }
    };

    if(user && token) {
      fetchNotifications();
    }
    
  }, [user, token]);

  const togglePanel = () => {
    setIsPanelVisible(!isPanelVisible);
  };

  const toggleHelpPage = () => {
    setShowHelpPage(!showHelpPage);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (panelRef.current && !panelRef.current.contains(event.target as Node)) {
      setIsPanelVisible(false);
    }
  };

  const handleClickOutsideHelpPage = (event: MouseEvent) => {
    if (helpPageRef.current && !helpPageRef.current.contains(event.target as Node)) {
      setShowHelpPage(false);
    }
  };

  useEffect(() => {
    if (isPanelVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    if (showHelpPage) {
      document.addEventListener('mousedown', handleClickOutsideHelpPage);
    } else {
      document.removeEventListener('mousedown', handleClickOutsideHelpPage);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('mousedown', handleClickOutsideHelpPage);
    };
  }, [isPanelVisible, showHelpPage]);

  const handleTitleClick = async (notification: Notification) => {
    setSelectedNotification(notification);
    setIsDialogVisible(true);
  
    // Send the article ID to mark as seen
    if (user?.localAccountId) {
      try {
        await fetch('/api/mark-as-seen', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ userId: user.localAccountId, articleId: notification.id }),
        });
  
        // Update the local state to mark this notification as read
        setNotifications(prevNotifications =>
          prevNotifications.map(n =>
            n.id === notification.id ? { ...n, unread: false } : n
          )
        );
  
        // Re-evaluate the unread status
        const unreadExists = notifications.some(n => n.unread);
        setHasUnreadNotifications(unreadExists);
      } catch (error) {
        console.error('Failed to mark article as seen:', error);
      }
    }
  };

  const handleCloseDialog = () => {
    setIsDialogVisible(false);
    setSelectedNotification(null);
  };

  const formatTimeAgo = (dateString: string) => {
    const date = new Date(dateString);
    const now = new Date();
    const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);

    let interval = Math.floor(seconds / 31536000);
    if (interval >= 1) return `${interval}Y`;

    interval = Math.floor(seconds / 86400);
    if (interval >= 1) return `${interval}d`;

    interval = Math.floor(seconds / 3600);
    if (interval >= 1) return `${interval}h`;

    interval = Math.floor(seconds / 60);
    if (interval >= 1) return `${interval}m`;

    return `${Math.floor(seconds)}s`;
  };

  return (
    <div className="header">
      <div className="assistant-profile">
        <div className="assistant-logo-area">
          <img src={ashleyLogo} alt="Ashley" className="assistant-logo" />
        </div>
        <h1 className="header-title">Ashley AI Chat</h1>
      </div>
      <div className="header-content">
      <div className="notification-icon-area" onClick={togglePanel}>
        <MdNotifications className="notification-icon" color="gray" />
        {notifications.some(notification => notification.unread) && (
          <span className="notification-count">
            {notifications.filter(notification => notification.unread).length}
          </span>
        )}
      </div>
        {isPanelVisible && (
          <div className="notification-panel" ref={panelRef}>
            {notifications.map((notification, index) => (
              <div key={index} className="notification-item">
                <div className="notification-left">
                  <MdArticle
                    className="notification-article-icon"
                    style={{ color: notification.unread ? 'orange' : 'gray' }}
                  />
                  <span className="notification-title" onClick={() => handleTitleClick(notification)}>
                    {notification.title}
                  </span>
                </div>
                <span className="notification-age">{formatTimeAgo(notification.createdAt)}</span>
              </div>
            ))}
          </div>
        )}

        <div className="help-page-icon-area" onClick={toggleHelpPage}>
          <MdHelpOutline className="help-page-icon" color="gray" />
        </div>

        {showHelpPage && (<div className="help-page-panel" ref={helpPageRef}>
          <HelpPage showHelpPage={showHelpPage}></HelpPage>
        </div>)}

        <div className="user-profile">
          <div className="profile-initials">{userInitials}</div>
        </div>
      </div>
      {isDialogVisible && selectedNotification && (
        <div className="dialog-overlay" onClick={handleCloseDialog}>
          <div className="dialog-content" onClick={e => e.stopPropagation()}>
            <button className="dialog-close-button" onClick={handleCloseDialog}>&times;</button>
            <h2>{selectedNotification.title}</h2>
            <div
              className="dialog-body"
              dangerouslySetInnerHTML={{ __html: selectedNotification.content }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
