import React, { useRef, useState, useEffect, ChangeEvent } from 'react';
import { Stage, Layer, Image as KonvaImage, Line, Circle } from 'react-konva';
import { FaCircle, FaUndoAlt, FaRedoAlt, FaTimesCircle, FaArrowCircleUp } from 'react-icons/fa';
import { uploadFileToBlob } from '../utils/fileUtils';
import { v4 as uuidv4 } from 'uuid';
import Konva from 'konva';
import useAuth from '../hooks/useAuth';

interface ImageSelectorProps {
  closeModal: () => void;
  imageUrl: string;
  onGenerateImages: (baseImage: string, maskImage: string, prompt: string) => void;
}

const ImageSelector: React.FC<ImageSelectorProps> = ({ closeModal, imageUrl, onGenerateImages }) => {
  const [image, setImage] = useState<HTMLImageElement | null>(null);
  const [strokes, setStrokes] = useState<Array<{ path: Array<{ x: number; y: number }>; brushSize: number }>>([]);
  const [redoStack, setRedoStack] = useState<Array<{ path: Array<{ x: number; y: number }>; brushSize: number }>>([]);
  const [currentStroke, setCurrentStroke] = useState<{ path: Array<{ x: number; y: number }>; brushSize: number } | null>(null);
  const [isDrawing, setIsDrawing] = useState<boolean>(false);
  const [dynamicHeight, setDynamicHeight] = useState<number>(0);
  const [dynamicWidth, setDynamicWidth] = useState<number>(0);
  const [dynamicScale, setDynamicScale] = useState<number>(1);
  const [cursorPosition, setCursorPosition] = useState<{ x: number; y: number } | null>(null);
  const [editPrompt, setEditPrompt] = useState<string>('');
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [brushSize, setBrushSize] = useState(70);
  const stageRef = useRef<Konva.Stage>(null);
  const maskLayerRef = useRef<Konva.Layer>(null);
  const exportLayerRef = useRef<Konva.Layer>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const { token } = useAuth();

  useEffect(() => {
    const img = new window.Image();
    img.crossOrigin = "anonymous";
    img.src = imageUrl;
    img.onload = () => {
      const { canvasWidth, canvasHeight, scale } = getCanvasSize(
        window.innerWidth * 0.85,
        window.innerHeight * 0.85,
        img.width,
        img.height
      );
      setDynamicWidth(canvasWidth);
      setDynamicHeight(canvasHeight);
      setDynamicScale(scale);
      setImage(img);
    };
  }, [imageUrl]);

  const getCanvasSize = (maxWidth: number, maxHeight: number, imageWidth: number, imageHeight: number) => {
    // Ensure scaling down only if image is larger than max dimensions
    if (imageWidth <= maxWidth && imageHeight <= maxHeight) {
      // Return original size and a scale of 1 if the image is smaller than the preferred size
      return { canvasWidth: imageWidth, canvasHeight: imageHeight, scale: 1 };
    }
  
    // Continue with scaling logic if image is larger than the preferred size
    if (imageWidth === imageHeight) {
      const size = Math.min(maxWidth, maxHeight);
      const scale = size / imageWidth;
      return { canvasWidth: size, canvasHeight: size, scale };
    } else if (imageWidth > imageHeight) {
      const scaledWidth = maxWidth;
      const scaledHeight = (imageHeight / imageWidth) * scaledWidth;
      const scale = scaledWidth / imageWidth;
      if (scaledHeight > maxHeight) {
        const adjustedHeight = maxHeight;
        const adjustedWidth = (imageWidth / imageHeight) * adjustedHeight;
        const adjustedScale = adjustedHeight / imageHeight;
        return { canvasWidth: adjustedWidth, canvasHeight: adjustedHeight, scale: adjustedScale };
      }
      return { canvasWidth: scaledWidth, canvasHeight: scaledHeight, scale };
    } else {
      const scaledHeight = maxHeight;
      const scaledWidth = (imageWidth / imageHeight) * scaledHeight;
      const scale = scaledHeight / imageHeight;
      if (scaledWidth > maxWidth) {
        const adjustedWidth = maxWidth;
        const adjustedHeight = (imageHeight / imageWidth) * adjustedWidth;
        const adjustedScale = adjustedWidth / imageWidth;
        return { canvasWidth: adjustedWidth, canvasHeight: adjustedHeight, scale: adjustedScale };
      }
      return { canvasWidth: scaledWidth, canvasHeight: scaledHeight, scale };
    }
  };
  
  const handleBrushSizeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setBrushSize(Number(e.target.value));
  };

  const handleClearSelections = () => {
    setStrokes([]); // Clear all stored strokes
    setRedoStack([]); // Clear redo stack
    setCurrentStroke(null); // Reset any current drawing stroke
  };

  const handleUndo = () => {
    if (strokes.length > 0) {
      const lastStroke = strokes[strokes.length - 1];
      setStrokes(prevStrokes => prevStrokes.slice(0, -1));
      setRedoStack(prevRedoStack => [...prevRedoStack, lastStroke]);
    }
  };

  const handleRedo = () => {
    if (redoStack.length > 0) {
      const lastRedoStroke = redoStack[redoStack.length - 1];
      setRedoStack(prevRedoStack => prevRedoStack.slice(0, -1));
      setStrokes(prevStrokes => [...prevStrokes, lastRedoStroke]);
    }
  };

  const handleMouseDown = (e: Konva.KonvaEventObject<MouseEvent>) => {
    const pos = e.target.getStage()?.getPointerPosition();
    if (pos) {
      setCurrentStroke({ path: [{ x: pos.x, y: pos.y }], brushSize });
      setIsDrawing(true);
    }
  };

  const handleMouseMove = (e: Konva.KonvaEventObject<MouseEvent>) => {
    const stage = e.target.getStage();
    if (stage) {
      const pos = stage.getPointerPosition();
      if (pos) {
        setCursorPosition(pos);
      }
    }

    if (!isDrawing || !currentStroke) return;
    const pos = e.target.getStage()?.getPointerPosition();
    if (pos) {
      setCurrentStroke(prev => ({
        path: [...(prev?.path || []), { x: pos.x, y: pos.y }],
        brushSize: prev?.brushSize || brushSize,
      }));
    }
  };

  const handleMouseUp = () => {
    if (currentStroke) {
      setStrokes(prevStrokes => [...prevStrokes, currentStroke]);
      setCurrentStroke(null);
      setRedoStack([]); // Clear redo stack after a new stroke
    }
    setIsDrawing(false);
  };

  const iconButtonStyle = {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    fontSize: '16px',
    color: '#808080',
    padding: '0',
  };
  
  const clearButtonStyle = {
    ...iconButtonStyle,
    color: '#808080',
    fontWeight: 'bold',
    cursor: 'pointer',
  };

  const generateMaskAndImage = async () => {
    if (!exportLayerRef.current || !image || buttonDisabled) return;
    setButtonDisabled(true);

    try {
      const maskCanvas = document.createElement('canvas');
      maskCanvas.width = image.width;
      maskCanvas.height = image.height;
      const maskCtx = maskCanvas.getContext('2d');

      if (maskCtx) {
        maskCtx.fillStyle = 'black';
        maskCtx.fillRect(0, 0, maskCanvas.width, maskCanvas.height);

        exportLayerRef.current.opacity(1);
        exportLayerRef.current.draw(); // Ensure the layer is rendered

        const scaledDataUrl = exportLayerRef.current.toDataURL({
          pixelRatio: image.width / dynamicWidth,
        });

        // Reset export layer opacity to 0
        exportLayerRef.current.opacity(0);
        exportLayerRef.current.draw(); // Redraw to reset visibility
        
        const img = new Image();
        img.onload = () => {
          maskCtx.drawImage(img, 0, 0);

          maskCanvas.toBlob(async (blob) => {
            if (blob && token) {
              const maskFile = new File([blob], `ai_image_edit_mask_${uuidv4()}.png`, { type: 'image/png' });
              const maskImageUrl = await uploadFileToBlob(maskFile, token);
              console.log(maskImageUrl);
              onGenerateImages(imageUrl, maskImageUrl, editPrompt);
            }
          }, 'image/png');
        };
        img.src = scaledDataUrl;
      }
    } catch (error) {
      console.error('Error generating mask and image:', error);
      alert('Failed to generate mask and image. Please ensure the image is from a CORS-enabled source.');
    }
    setButtonDisabled(false);
  };
  
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !buttonDisabled) {
      generateMaskAndImage();
    }
  };

  return (
    <div
      ref={containerRef}
      style={{
        position: 'relative',
        display: 'inline-block',
        width: '98dvw',
        height: '98dvh',
        margin: 'auto',
        alignContent: 'center',
        overflow: 'hidden',
      }}
    >
      <button onClick={closeModal} className="close-modal-button">
        <FaTimesCircle size={30} color='gray' />
      </button>

      <div
        style={{
          position: 'absolute',
          top: '10px',
          left: '10px',
          zIndex: 1000,
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          color: '#808080',
        }}
      >
        <FaCircle size={10} /> {/* Small Circle Icon */}
        <input
          type="range"
          id="brushSize"
          min="20"
          max="100"
          value={brushSize}
          onChange={handleBrushSizeChange}
          style={{
            width: '120px',
            appearance: 'none',
            backgroundColor: '#ccc',
            borderRadius: '5px',
            height: '4px',
            cursor: 'pointer',
          }}
        />
        <FaCircle size={18} />

        <div style={{ borderLeft: '1px solid #ccc', height: '24px', margin: '0 10px' }}></div>

        <button onClick={handleUndo} disabled={strokes.length === 0} style={iconButtonStyle}>
          <FaUndoAlt size={18} />
        </button>


        <button onClick={handleRedo} disabled={redoStack.length === 0} style={iconButtonStyle}>
          <FaRedoAlt size={18} />
        </button>

        <div style={{ borderLeft: '1px solid #ccc', height: '24px', margin: '0 10px' }}></div>


        <button onClick={handleClearSelections} style={clearButtonStyle} disabled={strokes.length === 0}>
          Clear Selection
        </button>
      </div>

      <Stage
        width={dynamicWidth}
        height={dynamicHeight}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        ref={stageRef}
      >
        <Layer>
          {image && (
            <KonvaImage
              image={image}
              width={image.width}
              height={image.height}
              scaleX={dynamicScale}
              scaleY={dynamicScale}
            />
          )}
        </Layer>
        <Layer ref={maskLayerRef}>
          {strokes.map((stroke, i) => (
            <Line
              key={i}
              points={stroke.path.flatMap(point => [point.x, point.y])}
              stroke="rgba(0, 0, 255, 0.3)"
              strokeWidth={stroke.brushSize}
              lineCap="round"
              lineJoin="round"
            />
          ))}
          {currentStroke && (
            <Line
              points={currentStroke.path.flatMap(point => [point.x, point.y])}
              stroke="rgba(0, 0, 255, 0.3)"
              strokeWidth={currentStroke.brushSize}
              lineCap="round"
              lineJoin="round"
            />
          )}
        </Layer>

        <Layer ref={exportLayerRef} opacity={0}>
          {strokes.map((stroke, i) => (
            <Line
              key={i}
              points={stroke.path.flatMap(point => [point.x, point.y])}
              stroke="white"
              strokeWidth={stroke.brushSize}
              lineCap="round"
              lineJoin="round"
            />
          ))}
          {currentStroke && (
            <Line
              points={currentStroke.path.flatMap(point => [point.x, point.y])}
              stroke="white"
              strokeWidth={currentStroke.brushSize}
              lineCap="round"
              lineJoin="round"
            />
          )}
        </Layer>
        <Layer>
          {cursorPosition && (
            <Circle
              x={cursorPosition.x}
              y={cursorPosition.y}
              radius={brushSize / 2}
              stroke="gray"
              strokeWidth={2}
              fill="rgba(0, 0, 255, 0.3)"
            />
          )}
        </Layer>
      </Stage>
      <div
        style={{
          position: 'absolute',
          bottom: '0px',
          left: '0px',
          right: '0px',
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          minHeight: '50px',
          maxHeight: '50px',
          marginTop: '5px',
          width: '99%',
          zIndex: 1000,
        }}
      >
        <input
          type="text"
          value={editPrompt}
          onChange={(e) => setEditPrompt(e.target.value)}
          onKeyDown={handleKeyPress}
          placeholder="Enter image edit prompt"
          style={{
            flex: 1,
            padding: '10px',
            borderRadius: '5px',
            border: '1px solid #ccc',
          }}
        />
        <button
          onClick={generateMaskAndImage}
          disabled={buttonDisabled}
          className='send-icon'
          style={{
            color: 'white',
            border: 'none',
            cursor: 'pointer',
          }}
        >
          <FaArrowCircleUp className="send-icon" color={buttonDisabled ? 'lightgray' : 'gray'} />
        </button>
      </div>
    </div>
  );
};

export default ImageSelector;