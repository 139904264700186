// frontend/src/chatReducer.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { Message } from './types';
import { stream } from 'xlsx';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from './store';

export const selectIsSending = (conversationId: string) =>
  createSelector(
    (state: RootState) => state.chat.conversations,
    (conversations) => {
      const conversation = conversations.find(c => c.id === conversationId);
      return conversation ? conversation.isSending : false;
    }
  );
  
export interface Attachment {
  name: string;
  url: string;
}

export interface Conversation {
  id: string;
  title: string;
  messages: Message[];
  attachments: Attachment[];
  streamingText: string;
  isSending: boolean;
  finishReason: string | null;
}

export interface ChatState {
  conversations: Conversation[];
  selectedConversationId: string | null;
  websockets: Record<string, WebSocket | null>;
}

const newId = uuidv4();

const migrateConversationsToUUID = (conversations: Conversation[]): Conversation[] => {
  return conversations.map(conversation => {
    if (!conversation?.id?.includes('-')) {
      return {
        ...conversation,
        id: uuidv4()
      };
    }
    return conversation;
  });
};

const initialState: ChatState = {
  conversations: [
    { id: newId, title: 'New chat', messages: [], attachments: [], streamingText: '', isSending: false, finishReason: null }
  ],
  selectedConversationId: newId,
  websockets: {},
};

const chatSlice = createSlice({
  name: 'chatMessages',
  initialState,
  reducers: {
    addWebSocket: (state, action: PayloadAction<{ conversationId: string; ws: WebSocket | null }>) => {
      const { conversationId, ws } = action.payload;
      state.websockets[conversationId] = ws;
    },
    removeWebSocket: (state, action: PayloadAction<{ conversationId: string }>) => {
      const { conversationId } = action.payload;
      if (state.websockets[conversationId]) {
        state.websockets[conversationId]?.close();
        delete state.websockets[conversationId];
      }
    },
    addMessage: (state, action: PayloadAction<{ conversationId: string; message: Message }>) => {
      const { conversationId, message } = action.payload;
      const conversation = state.conversations.find(c => c.id === conversationId);
      if (conversation) {
        if (message?.content[0]?.type == "text" && message.content[0].text) {
          message.content[0].text = message.content[0].text?.replace(/<AI_STATUS>.*?<\/AI_STATUS>/g, '').trim();
        }
        conversation.messages.push(message);

        const conversationIndex = state.conversations.findIndex(c => c.id === conversationId);
        if (conversationIndex !== -1 && conversationIndex !== 0) {
          state.conversations.splice(conversationIndex, 1);
          state.conversations.unshift(conversation);
        }
      }
    },
    addAttachment: (state, action: PayloadAction<{ conversationId: string; attachment: Attachment }>) => {
      const { conversationId, attachment } = action.payload;
      const conversation = state.conversations.find(c => c.id === conversationId);
      if (conversation) {
        conversation.attachments.push(attachment);
      }
    },
    addStreamedMessage: (state, action: PayloadAction<{ conversationId: string; }>) => {
      const { conversationId } = action.payload;
      const conversation = state.conversations.find(c => c.id === conversationId);
      
      if (conversation) {
        if (conversation.streamingText) {
          conversation.streamingText = conversation.streamingText.replace(/<AI_STATUS>.*?<\/AI_STATUS>/g, '').trim();
        }

        const lastMessage = conversation.messages[conversation.messages.length - 1];
        if (lastMessage && lastMessage.role === "assistant" && lastMessage.content[0].type == "text") {
          lastMessage.content[0].text += conversation.streamingText;
        } else {
          conversation.messages.push({ role: "assistant", content: [{ type:"text", text: conversation.streamingText}], status: null} as Message);
        }
      }
    },
    loadMessages: (state, action: PayloadAction<{ conversationId: string; messages: Message[]; attachments: Attachment[] }>) => {
      const { conversationId, messages, attachments } = action.payload;
      const conversation = state.conversations.find(c => c.id === conversationId);
      if (conversation) {
        conversation.messages = messages || [];
        conversation.attachments = attachments || [];
      }
    },
    clearMessages: (state) => {
      state.conversations.forEach(conversation => {
        conversation.messages = [];
        conversation.attachments = [];
        conversation.streamingText = '';
      });
    },
    selectConversation: (state, action: PayloadAction<string>) => {
      state.selectedConversationId = action.payload;
    },
    addConversation: (state, action: PayloadAction<Partial<Conversation>>) => {
      const newConversation: Conversation = {
        ...action.payload,
        id: uuidv4(),
        title: action.payload.title || 'New chat',
        messages: [],
        attachments: [],
        streamingText: '',
        isSending: false,
        finishReason: null,
      };
      state.conversations.unshift(newConversation);
      state.selectedConversationId = newConversation.id;
    },
    renameConversation: (state, action: PayloadAction<{ conversationId: string; newTitle: string }>) => {
      const { conversationId, newTitle } = action.payload;
      const conversation = state.conversations.find(c => c.id === conversationId);
      if (conversation) {
        conversation.title = newTitle;
      }
    },
    deleteConversation: (state, action: PayloadAction<string>) => {
      state.conversations = state.conversations.filter(c => c.id !== action.payload);
      if (state.selectedConversationId === action.payload) {
        state.selectedConversationId = state.conversations.length > 0 ? state.conversations[0].id : null;
      }
    },
    updateId: (state, action: PayloadAction<{ conversationId: string }>) => {
      const { conversationId } = action.payload;
      const conversation = state.conversations.find(c => c.id === conversationId);
      if (conversation) {
        conversation.id = uuidv4();
      }
    },
    migrateConversations: (state) => {
      state.conversations = migrateConversationsToUUID(state.conversations);
      if (!state.selectedConversationId?.includes('-')) {
        state.selectedConversationId = state.conversations[0]?.id || null;
      }
    },
    setIsSending: (state, action: PayloadAction<{ conversationId: string; isSending: boolean }>) => {
      const { conversationId, isSending } = action.payload;
      
      const conversation = state.conversations.find(c => c.id === conversationId);
      if (conversation) {
        conversation.isSending = isSending;
      }
    },
    setFinishReason: (state, action: PayloadAction<{ conversationId: string; finishReason: string | null }>) => {
      const { conversationId, finishReason } = action.payload;
      const conversation = state.conversations.find(c => c.id === conversationId);
      if (conversation) {
        conversation.finishReason = finishReason;
      }
    },
    updateStreamingText: (state, action: PayloadAction<{ conversationId: string; content: string }>) => {
      const { conversationId, content } = action.payload;
      const conversation = state.conversations.find(c => c.id === conversationId);
      if (conversation) {
        conversation.streamingText += content;
      }
    },
    clearStreamingText: (state, action: PayloadAction<string | 'all'>) => {
      const conversationId = action.payload;
      if (conversationId === 'all') {
        state.conversations.forEach(conversation => {
          conversation.streamingText = '';
          conversation.isSending = false;
          conversation.finishReason = null;
        });
      } else {
        const conversation = state.conversations.find(c => c.id === conversationId);
        if (conversation) {
          conversation.streamingText = '';
          conversation.isSending = false;
          conversation.finishReason = null;
        }
      }
    },
  },
});

export const { 
  addMessage, 
  addAttachment, 
  addStreamedMessage, 
  loadMessages, 
  clearMessages, 
  selectConversation, 
  addConversation, 
  renameConversation, 
  deleteConversation, 
  updateId, 
  migrateConversations, 
  updateStreamingText,
  clearStreamingText,
  setIsSending,
  setFinishReason,
  addWebSocket,
  removeWebSocket 
} = chatSlice.actions;

export default chatSlice.reducer;
