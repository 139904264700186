// frontend/src/components/ExportDropdown.tsx
import React, { useState, useRef, useEffect } from 'react';
import { FaFileWord, FaMarkdown } from 'react-icons/fa';
import { FiChevronDown } from 'react-icons/fi';
import { exportToWord, exportToMarkdown } from '../utils/exportUtils';
import { Message } from '../types';

interface ExportDropdownProps {
  messages: Message[];
  conversationId: string;
  initialFormat?: string;
}

const ExportDropdown: React.FC<ExportDropdownProps> = ({ messages, conversationId, initialFormat = 'Word' }) => {
  const [exportFormat, setExportFormat] = useState<string>(initialFormat);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const exportIcon = exportFormat === 'Word' ? <FaFileWord size={16} /> : <FaMarkdown size={16} />;

  const handleExportClick = () => {
    if (exportFormat === 'Word') {
      exportToWord('chatContainer');
    } else {
      exportToMarkdown(messages, conversationId);  // Pass your messages here if needed
    }
  };

  const handleFormatSelect = (format: string) => {
    setExportFormat(format);
    setIsDropdownOpen(false);
  };

  // Close dropdown if clicking outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div ref={dropdownRef} style={{ 
      position: 'relative', 
      display: 'inline-block',
      zIndex: 1000
      }}>
      <div className="export-button-container" 
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '8px 12px',
          borderRadius: '4px',
          border: '1px solid #ccc',
          cursor: 'pointer'
        }}>
        
        {/* Export section */}
        <div 
          onClick={handleExportClick}
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            flex: 1,
            height: '100%',  // Ensure full height
            position: 'relative'  // Add positioning context
          }}>
          {exportIcon}
          <span style={{ 
            marginLeft: '8px',
            position: 'relative',  // Add positioning
            display: 'block',      // Make block level
            width: '100%',         // Full width
            height: '100%',        // Full height
            lineHeight: '20px'     // Match text height
            }}>
            Export Chat
          </span>
        </div>

        {/* Dropdown toggle section */}
        <div
          onClick={(e) => {
            setIsDropdownOpen(!isDropdownOpen);
          }}
          style={{ 
            cursor: 'pointer',
            padding: '0 4px',
            marginLeft: '8px',
            height: '100%',        // Full height
            display: 'flex',       // Center icon
            alignItems: 'center'   // Center icon
          }}>
          <FiChevronDown size={20} />
        </div>
      </div>

      {/* Dropdown menu */}
      {isDropdownOpen && (
        <div className="dropdown-menu" 
          style={{
            position: 'absolute',
            top: '100%',
            left: 0,
            right: 0,
            border: '1px solid #ccc',
            borderRadius: '4px',
            backgroundColor: '#fff',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            zIndex: 1000
        }}>
          <div
            onClick={() => { handleFormatSelect('Word'); exportToWord('chatContainer'); }}
            style={{
              padding: '8px 12px',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
          }}>
            <FaFileWord size={16} style={{ marginRight: '8px' }} />
            Word
          </div>
          <div
            onClick={() => { handleFormatSelect('Markdown'); exportToMarkdown(messages, conversationId); }}
            style={{
              padding: '8px 12px',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <FaMarkdown size={16} style={{ marginRight: '8px' }} />
            Markdown
          </div>
        </div>
      )}
    </div>
  );
};

export default ExportDropdown;
