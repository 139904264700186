import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

const useAuth = () => {
    const { instance, accounts, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [token, setToken] = useState(null);
    const [expiration, setExpiration] = useState(null);

    useEffect(() => {
        const authenticateAndCheckRoles = async () => {
            if (!isAuthenticated && inProgress === 'none') {
                await instance.loginRedirect();
            }

            if (isAuthenticated) {
                try {
                    const account = accounts[0];
                    const response = await instance.acquireTokenSilent({
                        scopes: ["650ce438-b6ac-40c9-8226-5c8f45213dcf/.default"],
                        account: account
                    });
                    setToken(response.accessToken);
                    setExpiration(response.expiresOn); // Track expiration time

                    const roles = account?.idTokenClaims?.roles || [];

                } catch (error) {
                    if (error instanceof InteractionRequiredAuthError) {
                        instance.loginRedirect();
                    }
                }
            }
        };

        authenticateAndCheckRoles();
    }, [isAuthenticated, inProgress, instance, accounts]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (expiration && Date.now() >= expiration - 65000) { // Refresh 30 seconds before expiration
                console.log('token expired, refreshing.');
                instance.acquireTokenSilent({
                    scopes: ["650ce438-b6ac-40c9-8226-5c8f45213dcf/.default"],
                    account: accounts[0]
                }).then(response => {
                    setToken(response.accessToken);
                    setExpiration(response.expiresOn);
                    console.log('token refreshed.');
                }).catch(error => {
                    instance.loginRedirect();
                });
            }
            else {
                console.log(`token valid until: ${expiration}`);
            }
        }, 60000); // Check every 60 seconds

        return () => clearInterval(interval);
    }, [expiration, instance, accounts]);

    return { isAuthenticated, user: accounts[0], token };
};


export default useAuth;