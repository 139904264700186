import React from 'react';
import { useDispatch } from 'react-redux';
import { clearStreamingText } from '../chatReducer';

interface ContinueButtonProps {
  finishReason: string | null;
  conversationId: string;
  streamingText: string;
  messages: any[];
  handleContinue: (streamingText: string, messages: any[]) => void;
}

const ContinueButton: React.FC<ContinueButtonProps> = ({
  finishReason,
  conversationId,
  streamingText,
  messages,
  handleContinue,
}) => {
  const dispatch = useDispatch();

  if (finishReason !== 'length') return null;

  const handleClick = () => {
    dispatch(clearStreamingText(conversationId)); // Clear streamingText before continuing
    handleContinue(streamingText, messages);
  };

  return (
    <div className="continue-button-container">
      <button
        className="continue-button"
        onClick={handleClick}
      >
        Continue Generating
      </button>
    </div>
  );
};

export default ContinueButton;
