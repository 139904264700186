// frontend/src/components/MessageList.tsx
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Content, Message } from '../types';
import MessageContent from './MessageContent';
import { FaCircle } from 'react-icons/fa';
import ImageSelector from './ImageSelector';
import { setIsSending, setFinishReason, clearStreamingText } from '../chatReducer';
import { RootState } from '../store';
import { v4 as uuidv4 } from 'uuid';

interface MessageListProps {
  messages: Message[];
  streamingText: string;
  conversationId: string;
  sendMessage: (newContent: Content[], messages: any[], addToMessages: boolean, messageTextOverride: string | null) => Promise<void>;
}

const MessageList: React.FC<MessageListProps> = React.memo(({ messages, streamingText, conversationId, sendMessage }) => {
  const conversation = useSelector((state: RootState) => state.chat.conversations.find(c => c.id === conversationId));
  const isSending = conversation ? conversation.isSending : false;
  const finishReason = conversation ? conversation.finishReason : null;
  
  let status = "";
  const dispatch = useDispatch();

  const statusRegex = /<AI_STATUS>(.*?)<\/AI_STATUS>/g;
  let match;
  while ((match = statusRegex.exec(streamingText)) !== null) {
    status = match[1].trim();
  }

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState('');

  const openModal = (imageUrl: string) => {
    setSelectedImageUrl(imageUrl);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedImageUrl('');
  };

  const handleGenerateImages = async (baseImage: string, maskImage: string, prompt: string) => {
    try {
      dispatch(setIsSending({ conversationId, isSending: true }));
      dispatch(setFinishReason({ conversationId, finishReason: null }));

      closeModal();

      await sendMessage([
        { type: 'text', text: `Create an image edit:\nbase_image_url: ${baseImage}\nmask_image_url: ${maskImage}\nprompt: ${prompt}` }
      ], messages, true, prompt);
  
    } catch (error) {
      console.error('Error processing images:', error);
    }
  };

  const filteredStreamingText = streamingText?.replace(statusRegex, '');

  return (
    <>
      {(messages || []).map((msg, index) => (
        <div key={index} className={`flex w-full flex-col gap-1 juice:empty:hidden`}>
          <div className={`relative rounded-3xl bg-[#f4f4f4] px-5 py-2.5 prose ${msg.role === 'user' ? 'max-w-[80%] items-end rtl:items-start user-message' : 'w-full bot-message'}`}>
          {Array.isArray(msg.content)
          ? msg.content.map((c, i) => (
              <MessageContent key={`${index}-${i}`} c={c} role={msg.role} openImageModal={openModal} id={`${conversationId}-${index}-${i}`} />
            ))
          : typeof msg.content === 'string'
          ? <MessageContent key="single" c={{ type: 'text', text: msg.content }} role={msg.role} openImageModal={openModal} id={`${conversationId}-${index}-0`} />
          : null}
          </div>
        </div>
      ))}
      {filteredStreamingText && (
        <div className={`flex w-full flex-col gap-1 juice:empty:hidden`}>
          <div className={`relative w-full rounded-3xl bg-[#f4f4f4] px-5 py-2.5 prose bot-message`}>
            <MessageContent key="streaming" c={{ type: 'text', text: filteredStreamingText }} role="assistant" openImageModal={openModal} id={`${uuidv4()}`} />
          </div>
        </div>
      )}
      {(isSending || filteredStreamingText) && (
        <div className={`flex w-full flex-col gap-1 juice:empty:hidden`}>
          <div className={`relative w-full rounded-3xl bg-[#f4f4f4] px-5 py-2.5 prose bot-message`}>
            <p>{status}</p>
            <FaCircle style={{ color: 'gray', fontSize: '10px' }} />
          </div>
        </div>
      )}

      {/* Modal for full-size image and ImageSelector */}
      {modalOpen && (
        <div className="modal-backdrop" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <ImageSelector
              closeModal={closeModal}
              imageUrl={selectedImageUrl}
              onGenerateImages={handleGenerateImages}
            />
          </div>
        </div>
      )}
    </>
  );
});

MessageList.displayName = 'MessageList';

export default MessageList;
