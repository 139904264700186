import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ChatInput from './ChatInput';
import Header from './Header';
import { Content, FileContent, ImageUrlContent, TextContent } from '../types';
import useDragAndDrop from '../hooks/useDragAndDrop';
import usePasteHandler from '../hooks/usePasteHandler';
import MessageList from './MessageList';
import DragOverlay from './DragOverlay';
import UploadingMessage from './UploadingMessage';
import ContinueButton from './ContinueButton';
import ChatDisclaimer from './ChatDisclaimer';
import useWebSocket from '../hooks/useWebSocket';
import ScrollToBottomButton from './ScrollToBottomButton';
import useAuth from '../hooks/useAuth';
import SessionTimeoutModal from './SessionTimeoutModal';
import { FiImage, FiFileText, FiEdit3, FiArrowRightCircle, FiBook } from 'react-icons/fi';
import { selectIsSending, setIsSending, setFinishReason, clearStreamingText } from '../chatReducer';
import { RootState } from '../store';
import ExportDropdown from './ExportDropdown';

const Chat: React.FC<{ conversationId: string }> = ({ conversationId }) => {
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [fileUrls, setFileUrls] = useState<{ name: string; url: string; icon: string }[]>([]);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isEmpty, setIsEmpty] = useState<boolean>(true);
  const exportDropdownRef = useRef<HTMLDivElement>(null); // Reference to the ExportDropdown
  // const lastScrollTop = useRef<number>(0);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);

  // Use useRef instead of state for scroll tracking
  const scrollStateRef = useRef({
    isUserScrolling: false,
    isScrolledUp: false
  });

    // Separate state just for the button visibility
    const [showScrollButton, setShowScrollButton] = useState(false);


  const dispatch = useDispatch();
  const { user } = useAuth();

  const setCurrentText = (input: string) => {
    if(inputRef?.current) {
      inputRef.current.value = input;
      inputRef.current.style.height = 'auto';
      inputRef.current.style.height = '50px;'
    }
  };

  const conversation = useSelector((state: RootState) =>
    state.chat.conversations.find((c: any) => c.id === conversationId)
  );

  const isSending = useSelector((state: RootState) => {
    const conversation = state.chat.conversations.find(c => c.id === conversationId);
    return conversation ? conversation.isSending : false;
  });

  const finishReason = useSelector((state: RootState) => {
    return conversation ? conversation.finishReason : '';
  });

  const messages = useSelector((state: RootState) => {
    return conversation ? conversation.messages : [];
  });

  const streamingText = conversation ? conversation.streamingText : '';

  useEffect(() => {
    setIsEmpty(messages.length === 0 && !streamingText);
  }, [messages, streamingText]);

  const handleSuggestionClick = (text: string) => {
    setCurrentText(text);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const { handleDragEnter, handleDragOver, handleDragLeave, handleDrop } = useDragAndDrop(
    conversationId, setImageUrls, setFileUrls, setIsDragging, setIsUploading, dispatch
  );
  usePasteHandler(inputRef, conversationId, setImageUrls, setFileUrls, setIsUploading, dispatch);

  const { sendMessage, handleContinue } = useWebSocket(
    user?.localAccountId, conversationId
  );

  const sendMessageContent = () => {
    const currentText = inputRef.current?.value.trim() || '';  
    if (currentText === '') return;

    const messageContent: Content[] = [
      ...(fileUrls ? fileUrls.map(fl => ({ type: 'file', file: { name: fl.name, url: fl.url, icon: fl.icon } } as FileContent)) : []),
      ...(imageUrls ? imageUrls.map(imageUrl => ({ type: 'image_url', image_url: { url: imageUrl } } as ImageUrlContent)) : []),
      ...(currentText ? [{ type: 'text', text: currentText } as TextContent] : [])
    ];

    dispatch(clearStreamingText(conversationId));  
    inputRef.current!.value = '';  
    setCurrentText('');
    setImageUrls([]);
    setFileUrls([]);
    dispatch(setIsSending({ conversationId, isSending: true }));
    dispatch(setFinishReason({ conversationId, finishReason: null }));
    if(chatContainerRef?.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
    scrollStateRef.current.isScrolledUp = false;
    scrollStateRef.current.isUserScrolling = false;
    sendMessage(messageContent, messages, true);  
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      sendMessageContent();  
    }
  };

  const cancelRequest = async () => {
    await sendMessage([{ type: "cancel" }], [], false)
    dispatch(setIsSending({ conversationId, isSending: false }));
    dispatch(setFinishReason({ conversationId, finishReason: null }));
    setCurrentText("");
    dispatch(clearStreamingText(conversationId));
  };

  
    const handleScroll = useCallback(() => {
      if (chatContainerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
        const buffer = 100;
        const isAtBottom = scrollHeight - scrollTop - clientHeight <= buffer;
        
        // Update ref without causing re-renders
        scrollStateRef.current = {
          isScrolledUp: !isAtBottom,
          isUserScrolling: !isAtBottom
        };
  
        // Only update state (and cause re-render) when button visibility needs to change
        setShowScrollButton(!isAtBottom);
      }
    }, []);
  
    // RAF-based scroll handler to reduce frequency of checks
    const handleScrollWithRAF = useCallback(() => {
      requestAnimationFrame(handleScroll);
    }, [handleScroll]);
  
    // Scroll event listener setup
    useEffect(() => {
      const container = chatContainerRef.current;
      if (container) {
        container.addEventListener('scroll', handleScrollWithRAF, { passive: true });
        
        // Initial check
        handleScroll();
        
        return () => container.removeEventListener('scroll', handleScrollWithRAF);
      }
    }, [handleScroll, handleScrollWithRAF]);
  
    // Auto-scroll to bottom for new messages
    useEffect(() => {
      if (!scrollStateRef.current.isUserScrolling && 
          chatContainerRef.current && 
          streamingText) {
        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
      }
    }, [messages, streamingText]);

  return (
    <div id="app-container" className="app-container">
      <div className="chat-wrapper" style={{ position: 'relative' }}>
        <Header />
        <div className="chat-container" id="chatContainer" ref={chatContainerRef}>
          <DragOverlay isDragging={isDragging} />
          <UploadingMessage isUploading={isUploading} />

          {isEmpty ? (
            <div className="empty-chat-prompt">
              <h2>What can I help with?</h2>
              <div className="suggestion-buttons">
                <button onClick={() => handleSuggestionClick('Create an image of ')}>
                  <FiImage size={20} /> Create Image
                </button>
                <button onClick={() => handleSuggestionClick('Make a plan ')}>
                  <FiArrowRightCircle size={20} /> Make a plan
                </button>
                <button onClick={() => handleSuggestionClick('Summarize this text ')}>
                  <FiFileText size={20} /> Summarize text
                </button>
                <button onClick={() => handleSuggestionClick('Help me write ')}>
                  <FiEdit3 size={20} /> Help me write
                </button>
                <button onClick={() => handleSuggestionClick('Research the following topic: ')}>
                  <FiBook size={20} /> Research
                </button>
              </div>
            </div>
          ) : (
            <>
              <MessageList messages={messages} streamingText={streamingText} conversationId={conversationId} sendMessage={sendMessage} />
              <ContinueButton
                finishReason={finishReason}
                conversationId={conversationId}
                streamingText={streamingText}
                messages={messages}
                handleContinue={handleContinue}
              />
              <div ref={exportDropdownRef}>
                <ExportDropdown messages={messages} conversationId={conversationId} />
              </div>
            </>
          )}          
        </div>
        <ScrollToBottomButton containerRef={chatContainerRef} visible={showScrollButton} />
        <ChatInput
          isSending={isSending}
          imageUrls={imageUrls}
          fileUrls={fileUrls}
          handleKeyDown={handleKeyDown}
          inputRef={inputRef}
          cancelRequest={cancelRequest}
          setImageUrls={setImageUrls}
          setFileUrls={setFileUrls}
          setIsUploading={setIsUploading}
          conversationId={conversationId}
        />
        <ChatDisclaimer />
        <SessionTimeoutModal isSending={isSending} streamingText={streamingText} />
      </div>
    </div>
  );
};

export default Chat;