import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Chat from './components/Chat';
import LeftPane from './components/LeftPane';
import { selectConversation, addConversation, updateId, migrateConversations } from './chatReducer';
import { FaBars } from 'react-icons/fa';
import useAuth from './hooks/useAuth';
import { v4 as uuidv4 } from 'uuid';
import mermaid from 'mermaid';

function App() {
  const { isAuthenticated } = useAuth();
  const dispatch = useDispatch();
  const conversations = useSelector((state: any) => state.chat.conversations);
  const selectedConversationId = useSelector((state: any) => state.chat.selectedConversationId);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [checkForDuplicateIds, setCheckForDuplicateIds] = useState(true);
  const sidebarRef = useRef<HTMLDivElement>(null);

  const handleSelectConversation = (id: string) => {
    dispatch(selectConversation(id));
    setIsSidebarOpen(false); // Close the sidebar on mobile after selecting a conversation
  };

  const handleNewConversation = () => {
    const newConversation = { id: uuidv4(), title: `New Chat`, messages: [] };
    dispatch(addConversation(newConversation));
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleClickOutside = (event: MouseEvent | TouchEvent) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target as Node)) {
      setIsSidebarOpen(false);
    }
  };

  useEffect(() => {
    if (isSidebarOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('touchstart', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [isSidebarOpen]);

  useEffect(() => {
    if (conversations && conversations.length < 1) {
      handleNewConversation();
    }
  }, [conversations]);

  // useEffect(() => {
  //   if (checkForDuplicateIds) {
  //     const ids = new Set();
  //     const duplicateIndices: number[] = [];
  //     console.log("checking for duplicate ids");
  //     conversations.forEach((conversation: { id: string; }, index: number) => {
  //       if (ids.has(conversation.id)) {
  //         duplicateIndices.push(index);
  //       } else {
  //         ids.add(conversation.id);
  //       }
  //     });

  //     duplicateIndices.forEach(index => {
  //       console.log("updating ids");
  //       dispatch(updateId({ conversationId: conversations[index].id }));
  //     });

  //     setCheckForDuplicateIds(false);
  //   }
  // }, [conversations, checkForDuplicateIds, dispatch]);

  // Perform migration to UUIDs on initial load
  useEffect(() => {
    dispatch(migrateConversations());
  }, [dispatch]);

  useEffect(() => {
    // Initialize Mermaid globally once when the app loads
    mermaid.initialize({ startOnLoad: false }); // Disable auto-start, we’ll call run explicitly
  }, []);

  if (!isAuthenticated) {
    // Show loading or login prompt
    return <div>Logging in ...</div>;
  }

  return (
    <div className="App" style={{ display: 'flex', height: '100vh' }}>
      <button className="menu-button" onClick={toggleSidebar}>
        <FaBars />
      </button>
      <div ref={sidebarRef} className={`left-pane ${isSidebarOpen ? 'open' : ''}`}>
        <LeftPane
          conversations={conversations}
          onSelectConversation={handleSelectConversation}
          onNewConversation={handleNewConversation}
        />
      </div>
      {selectedConversationId && <Chat conversationId={selectedConversationId} />}
    </div>
  );
}

export default App;
