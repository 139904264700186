// frontend/src/components/ChatInput.tsx
import React from 'react';
import { MdOutlineStopCircle } from 'react-icons/md';
import { FiPaperclip } from 'react-icons/fi';
import { FaArrowCircleUp } from 'react-icons/fa';
import ImagePreview from './ImagePreview';
import { handleFileUpload } from '../utils/fileUtils';
import useAuth from '../hooks/useAuth';

interface ChatInputProps {
  isSending: boolean;
  imageUrls: string[] | null;
  fileUrls: { name: string; url: string; icon: string }[] | null;
  handleKeyDown: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  inputRef: React.RefObject<HTMLTextAreaElement>;
  cancelRequest: () => Promise<void>;
  setImageUrls: React.Dispatch<React.SetStateAction<string[]>>;
  setFileUrls: React.Dispatch<React.SetStateAction<{ name: string; url: string; icon: string }[]>>;
  setIsUploading: React.Dispatch<React.SetStateAction<boolean>>;
  conversationId: string;
}

const ChatInput: React.FC<ChatInputProps> = ({
  isSending,
  imageUrls = [],
  fileUrls = [],
  handleKeyDown,
  inputRef,
  cancelRequest,
  setImageUrls,
  setFileUrls,
  setIsUploading,
  conversationId,
}) => {

  const { user, token, isAuthenticated } = useAuth();
  const handleChange = () => {
    const textarea = inputRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = Math.min(textarea.scrollHeight, window.innerHeight * 0.25) + 'px';
    }
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && token) {
      // const attachmentUrls = 
      await handleFileUpload(files, setImageUrls, setFileUrls, setIsUploading, conversationId, token);
    }
  };

  const handleSendClick = () => {
    // Simulate the Enter key press to send the message
    const event = new KeyboardEvent('keydown', {
      key: 'Enter',
      bubbles: true,
      cancelable: true,
    });
    inputRef.current?.dispatchEvent(event);
  };

  return (
    <div className="chat-input-area">
      <ImagePreview imageUrls={imageUrls} fileUrls={fileUrls} />
      <div className="chat-input-container">
        <div className="file-upload">
          <label htmlFor="file-upload-input">
            <FiPaperclip className="paperclip-icon" />
          </label>
          <input
            id="file-upload-input"
            type="file"
            multiple
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
        </div>
        <textarea
          ref={inputRef}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder="Type or paste your message here..."
          className="chat-input"
          dir="auto"
          rows={1}
        />
        <button
          onClick={isSending ? cancelRequest : handleSendClick}
          className="cancel-button"
        >
          {isSending ? (
            <MdOutlineStopCircle className="stop-icon" />
          ) : (
            <FaArrowCircleUp className="send-icon" />
          )}
        </button>
      </div>
    </div>
  );
};

export default ChatInput;
