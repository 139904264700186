import React, { useState, useEffect, useRef, useCallback } from 'react';
import mermaid from 'mermaid';
import { debounce } from 'lodash';
import '../MarkdownWithTabs.module.css';

interface MermaidContainerProps {
  content: string;  // The Mermaid content as a string
  id: string;      // Unique ID for the chart instance
}

const MermaidContainer: React.FC<MermaidContainerProps> = React.memo(({ content, id }) => {
  const [activeTab, setActiveTab] = useState<'chart' | 'code'>('chart');
  const [chartContent, setChartContent] = useState(content);
  const [svgContent, setSvgContent] = useState<string>(''); // Store SVG content in state
  const chartDivRef = useRef<HTMLDivElement | null>(null);
  const isMounted = useRef(true);

  useEffect(() => {
    setChartContent(content);
    return () => {
      isMounted.current = false;
    };
  }, [content]);

  const setTab = (tab: 'chart' | 'code') => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const renderMermaidChart = useCallback(async () => {
    if (!isMounted.current) return;

    try {
      if (id && chartContent && activeTab === 'chart' && chartDivRef.current) {
        const chartDiv = chartDivRef.current;
        if (!chartDiv) return;

        // Only render if we don't have SVG content or if content has changed
        if (!svgContent) {
          try {
            const { svg } = await mermaid.render(`svg-${id}`, chartContent, chartDiv);
            if (isMounted.current) {
              setSvgContent(svg);
              chartDiv.innerHTML = svg;
            }
          } catch (err) {
            const errorString = `<div style="color: red;"><p>Unable to render chart image from AI generated mermaid code.</p><p>Modify your prompt to try again, or click Code Tab to see generated code.</p></div>`;
            if (isMounted.current) {
              setSvgContent(errorString);
              chartDiv.innerHTML = errorString;
            }
            console.error('Mermaid render error:', err);
          }
        } else {
          // Use existing SVG content if available
          chartDiv.innerHTML = svgContent;
        }
      }
    } catch (err) {
      console.error('Mermaid render error:', err);
    }
  }, [chartContent, id, activeTab]);

  const debouncedRender = useCallback(debounce(() => renderMermaidChart(), 200), [renderMermaidChart]);

  useEffect(() => {
    if (isMounted.current && activeTab === 'chart') {
      debouncedRender();
    }

    return () => {
      debouncedRender.cancel();
    };
  }, [chartContent, id, activeTab]);

  const downloadImage = () => {
    try {
      if (!svgContent) {
        console.error(`SVG ${id} not present`);
        return;
      }

      const svgBlob = new Blob([svgContent], { type: 'image/svg+xml' });
      const svgUrl = URL.createObjectURL(svgBlob);
      const link = document.createElement('a');
      link.href = svgUrl;
      link.download = `${id}.svg`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(svgUrl); // Clean up the URL object
    } catch (error) {
      console.error('Error while downloading the SVG:', error);
    }
  };

  return (
    <div className="markdown-container">
      <div className="markdown-header" style={{ backgroundColor: '#ddd', minHeight: '50px' }}>
        <div className="markdown-header-content">
          <button
            onClick={downloadImage}
            className="markdown-button"
            style={{ right: '160px', backgroundColor: '#666' }}
          >
            Download
          </button>
          <button
            onClick={() => setTab('chart')}
            className="markdown-button"
            style={{ right: '80px', backgroundColor: '#666' }}
          >
            Chart
          </button>
          <button
            onClick={() => setTab('code')}
            className="markdown-button"
            style={{ backgroundColor: '#666' }}
          >
            Code
          </button>
        </div>
      </div>

      <div className="markdown-content">
        {activeTab === 'chart' && (
          <div className="bg-white p-4 border border-[#ddd]">
            <div ref={chartDivRef} id={id} className="mermaid">
              Please wait while Chart is Generating ...
            </div>
          </div>
        )}

        {activeTab === 'code' && (
          <div className="bg-[#f4f4f4] p-4 border border-[#ddd]">
            <pre>{content}</pre>
          </div>
        )}
      </div>
    </div>
  );
}, (prevProps, nextProps) => {
  return (
    prevProps.id === nextProps.id &&
    prevProps.content === nextProps.content
  );
});

export default MermaidContainer;
