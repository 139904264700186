// src/utils/useFileIcon.ts

import { FaFilePdf, FaFileWord, FaFileExcel, FaFilePowerpoint, FaFileCsv, FaFileAudio, FaFileVideo, FaFile } from 'react-icons/fa';
import { IconType } from 'react-icons';

// Define the mapping of file types to icons
const iconMap: { [key: string]: IconType } = {
  pdf: FaFilePdf,
  doc: FaFileWord,
  docx: FaFileWord,
  xls: FaFileExcel,
  xlsx: FaFileExcel,
  ppt: FaFilePowerpoint,
  pptx: FaFilePowerpoint,
  csv: FaFileCsv,
  mp3: FaFileAudio,
  wav: FaFileAudio,
  mp4: FaFileVideo,
  avi: FaFileVideo,
  // Default icon for unknown file types
  default: FaFile,
};

// Utility function to get the icon based on file extension
export const useFileIcon = (fileName: string): IconType => {
  const extension = fileName.split('.').pop()?.toLowerCase() || '';
  return iconMap[extension] || iconMap.default;
};
