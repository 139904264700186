// frontend/src/components/HelpPage.tsx
import React from 'react';
import aiChatProcess from '../assets/ai-chat-process.png'
import afiSalesSample from '../assets/afi-sales-sample.png'
import mindMapSample from '../assets/mind-map-sample.png'
import webSearchSample from '../assets/web-search-sample.png'
import flowChartSample from '../assets/flowchart-sample.png'
import createAudioSample from '../assets/create-audio-sample.png'
import createImageSample from '../assets/create-image-sample.png'
import replaceItem1 from '../assets/replace-item-1.png'
import replaceItem2 from '../assets/replace-item-2.png'
import orgChart1 from '../assets/org-chart-1.png'
import orgChart2 from '../assets/org-chart-2.png'
import orgChart3 from '../assets/org-chart-3.png'

interface HelpPageProps {
  showHelpPage: boolean;
}

const HelpPage: React.FC<HelpPageProps> = ({ showHelpPage = false }) => {
  if (!showHelpPage) return null;

  return (
    <div className="prose" style={{ alignItems: 'start', textAlign: 'left', maxWidth: '80dvw', maxHeight: '80dvh', overflowY: 'scroll', margin: '10px 10px'}}>
      <h1>Welcome to Ashley AI Chat!</h1>

      <p>Ashley AI Chat is designed to make it easy for you to interact with powerful AI tools, tailored to help you get things done—whether youre exploring data, creating visuals, or finding product information. Heres how it works and what you can expect from your experience with Ashley AI Chat.</p>

      <h2>How It Works</h2>
      <p>Using Ashley AI Chat is simple. Just type in your question or task, and Ashley does the rest! Heres what happens behind the scenes:</p>
      <p><img src={aiChatProcess} /></p>
      <ul>
          <li><strong>Understanding Your Request:</strong> When you send a prompt, Ashley AI Chat determines your intent by analyzing your question or request. This allows us to focus precisely on what you need.</li>
          <li><strong>Choosing the Right Tools:</strong> Based on your request, we pick the best tools to provide an effective response. Ashley AI Chat can use one or more language models (advanced AI technologies) or additional tools to complete your task.</li>
          <li><strong>Processing Your Task:</strong> Our platform selects the AI model best suited to your task, such as answering questions, creating images, generating charts, or looking up product details. Once we have all the pieces, we send back a complete, ready-to-use response.</li>
      </ul>

      <h2>AI Models & Tools in Ashley AI Chat</h2>
      <p>Ashley AI Chat is packed with capabilities from leading AI models:</p>
      <ul>
          <li><strong>Language Models:</strong> We use some of the latest and most sophisticated language models, like Claude 3.5 Sonnet, Gemini 1.5 Pro, GPT-4o, DALL-E 3, and more. Each of these models excels in understanding and generating language, images, or data visuals based on your requests.</li>
      </ul>

      <p>Beyond the language models, weve included custom tools that make Ashley AI Chat even more powerful. Here are some things you can do:</p>
      <ul>
          <li><strong>Web Search & Research:</strong> Ashley can search the web, summarize content, and perform in-depth research to answer your questions with up-to-date information.</li>
          <li><strong>Visuals & Charts:</strong> Need visuals? Ashley can create various types of charts, including line and bar charts, mind maps, flowcharts, and org charts. Just describe what you need, and Ashley will handle the rest.</li>
          <li><strong>Audio Creation:</strong> You can generate audio responses, turning text into narrated audio clips that sound natural and engaging.</li>
          <li><strong>Image Creation & Editing:</strong> Using advanced image models like DALL-E 3 and ImageGen, Ashley can create or edit images based on your description.</li>
          <li><strong>Chat Transcript:</strong> Keep a record of your chat history by exporting the conversation to a Microsoft Word document with just one click.</li>
          <li><strong>Access Company Information:</strong> Ashley AI Chat is integrated with Ashleys Product Information and Company Sales Data, allowing it to pull relevant product details or sales figures when you need them.</li>
      </ul>

      <h2>Getting Started</h2>
      <p>Start a conversation by simply typing a question or prompt. Here are a few examples to try:</p>
      <ul>
        <li><strong>Web Search</strong><br/><em>&quot;Search the web for latest furniture trends.&quot;</em><br />Ashley will search the web and summarize the top results.<br/><img src={webSearchSample}/></li>
        <li><strong>Flowchart</strong><br/><em>&quot;Create a Flowchart of the DevOps Process.&quot;</em><br />Ashley will design a flowchart that you can download or share.<br/><img src={flowChartSample}/></li>
        <li><strong>Mind Map</strong><br/><em>&quot;Create a mind map of project stages.&quot;</em><br />Ashley will design a mind map that you can download or share.<br/><img src={mindMapSample}/></li>
        <li><strong>Org Chart</strong><br/><em>&quot;Create org chart.&quot;</em><br />Upload an Excel file and Ashley will design org chart that you can download or share.  Thes images tend to be very wide, so you may need to save image and open to view actual size.<br/><img src={orgChart1}/><br/><img src={orgChart2}/><br/><img src={orgChart3}/></li>
        <li><strong>Audio</strong><br/><em>&quot;Create audio for Darcy Sofa radio ad in Spanish (Mexico).&quot;</em><br />Ashley will generate a transcript and an Audio File in the requested locale.  You can also translate the text yo your native language for review.<br/><img src={createAudioSample}/></li>
        <li><strong>Image Creation</strong><br/><em>&quot;Create an image of a modern farmhouse living room.&quot;</em><br />Ashley will generate a detailed image based on simple input.<br/><img src={createImageSample}/></li>
        <li><strong>Image Mask Editing</strong><br/><em>&quot;Replace Plant.&quot;</em><br />Click on a Generated Image to open the Mask Editor.  Select a mask area and a prompt.  Ashley will generate an updated image.<br/><img src={replaceItem1}/><br/><img src={replaceItem2}/></li>
        <li><strong>AFI Sales Data</strong><br/><em>&quot;Show me AFI Sales Data for October 2024.&quot;</em><br />Ashley will fetch the latest sales figures from the company database.<br/><img src={afiSalesSample}/></li>
      </ul>

      <p>If you have any questions, our support team is here to help. Enjoy using Ashley AI Chat, your intelligent assistant for all things creative, informative, and productive!</p>
    </div>
  );
};

export default HelpPage;
