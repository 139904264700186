// frontend/src/hooks/useDragAndDrop.ts
import { useEffect, useCallback } from 'react';
import { handleFileUpload } from '../utils/fileUtils';
import React from 'react';
import useAuth from './useAuth';

const useDragAndDrop = (
  conversationId: string,
  setImageUrls: React.Dispatch<React.SetStateAction<string[]>>,
  setFileUrls: React.Dispatch<React.SetStateAction<{ name: string; url: string; icon: string }[]>>,
  setIsDragging: React.Dispatch<React.SetStateAction<boolean>>,
  setIsUploading: React.Dispatch<React.SetStateAction<boolean>>,
  dispatch: React.Dispatch<any>
) => {
  const { token } = useAuth();
  
  const handleDragEnter = useCallback((event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  }, [setIsDragging]);

  const handleDragOver = useCallback((event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const handleDragLeave = useCallback((event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
  }, [setIsDragging]);

  const handleDrop = useCallback(
    async (event: DragEvent) => {
      event.preventDefault();
      event.stopPropagation();
      setIsDragging(false);

      const dataTransfer = event.dataTransfer;
      if (dataTransfer) {
        const files = dataTransfer.files;
        if (files.length > 0 && token) {
          await handleFileUpload(files, setImageUrls, setFileUrls, setIsUploading, conversationId, token);
        }
      }
    },
    [setImageUrls, setFileUrls, setIsDragging, setIsUploading, conversationId, dispatch]
  );

  useEffect(() => {
    window.addEventListener('dragenter', handleDragEnter);
    window.addEventListener('dragover', handleDragOver);
    window.addEventListener('dragleave', handleDragLeave);
    window.addEventListener('drop', handleDrop);

    return () => {
      window.removeEventListener('dragenter', handleDragEnter);
      window.removeEventListener('dragover', handleDragOver);
      window.removeEventListener('dragleave', handleDragLeave);
      window.removeEventListener('drop', handleDrop);
    };
  }, [handleDragEnter, handleDragOver, handleDragLeave, handleDrop]);

  return { handleDragEnter, handleDragOver, handleDragLeave, handleDrop };
};

export default useDragAndDrop;
