// frontend/src/components/UploadingMessage.tsx
import React from 'react';
import { FiLoader } from 'react-icons/fi';

interface UploadingMessageProps {
  isUploading: boolean;
}

const UploadingMessage: React.FC<UploadingMessageProps> = React.memo(({ isUploading }) => {
  if (!isUploading) return null;
  return (
    <div className="uploading-message">
      <FiLoader className="spinner" /> Uploading files...
    </div>
  );
});

UploadingMessage.displayName = 'UploadingMessage';

export default UploadingMessage;
