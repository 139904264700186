// frontend/src/utils/fileUtils.ts
import axios from 'axios';
import { addMessage } from '../chatReducer';
import React from 'react';
import { Content } from '../types';
import useAuth from '../hooks/useAuth';

async function convertSvgToPngFile(file: File): Promise<File> {
  // Read and parse SVG
  const svgText = await file.text();
  const parser = new DOMParser();
  const svgDoc = parser.parseFromString(svgText, 'image/svg+xml');
  const svgElement = svgDoc.documentElement;

  // Get viewBox dimensions
  const viewBox = svgElement.getAttribute('viewBox')?.split(' ').map(Number);
  if (!viewBox) {
      throw new Error('SVG must have a viewBox');
  }

  // Calculate actual dimensions from viewBox
  const width = viewBox[2];
  const height = viewBox[3];

  // Set explicit dimensions on SVG
  svgElement.setAttribute('width', String(width));
  svgElement.setAttribute('height', String(height));

  console.log('Setting SVG dimensions:', { width, height });

  const canvas: HTMLCanvasElement = document.createElement('canvas');
  const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');
  
  if (!ctx) {
      throw new Error('Could not get canvas context');
  }

  const svgBlob: Blob = new Blob([svgElement.outerHTML], {type: 'image/svg+xml'});
  const url: string = URL.createObjectURL(svgBlob);
  const img: HTMLImageElement = new Image();
  
  try {
      const pngBlob = await new Promise<Blob>((resolve, reject) => {
          img.onload = (): void => {
              // Set canvas to viewBox dimensions
              canvas.width = width;
              canvas.height = height;

              // Draw at full size
              ctx.drawImage(img, 0, 0, width, height);
              
              canvas.toBlob(
                  (blob: Blob | null): void => {
                      if (blob) {
                          console.log('Final blob size:', blob.size);
                          resolve(blob);
                      } else {
                          reject(new Error('Failed to create blob from canvas'));
                      }
                  },
                  'image/png',
                  1.0
              );
          };
          
          img.onerror = (error: string | Event): void => {
              reject(error);
          };

          // Set image size before loading
          img.width = width;
          img.height = height;
          img.src = url;
      });

      const pngFilename = file.name.replace(/\.svg/, '.png');
      const pngFile = new File(
          [pngBlob],
          pngFilename,
          { type: 'image/png', lastModified: Date.now() }
      );

      return pngFile;

  } finally {
      URL.revokeObjectURL(url);
  }
}

export const uploadFileToBlob = async (file: File, token: string): Promise<string> => {

  // First, check if user is authenticated
  if (!token) {
    throw new Error('User is not authenticated');
  }

  const formData = new FormData();
  formData.append('file', file);

  // Configure headers
  const config = {
    headers: {
      'Authorization': `Bearer ${token}`,
      // If you need to handle files, you might also want to add:
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios.post<{ url: string }>(
      '/api/upload', 
      formData, 
      config
    );

    if(response?.data?.url?.startsWith('/attachments/')) {
      return new URL(response.data.url, window.location.origin).toString();
    }
    return response.data.url;
  } catch (error) {
    // Handle errors appropriately
    console.error('Upload failed:', error);
    throw error;
  }
};


const getFileIcon = (fileType: string): string => {
  if (fileType.startsWith('application/pdf')) return "FaFilePdf";
  if (fileType.startsWith('application/msword') || fileType.startsWith('application/vnd.openxmlformats-officedocument.wordprocessingml.document'))
    return "FaFileWord";
  if (fileType.startsWith('application/vnd.ms-excel') || fileType.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'))
    return "FaFileExcel";
  if (fileType.startsWith('application/vnd.ms-powerpoint') || fileType.startsWith('application/vnd.openxmlformats-officedocument.presentationml.presentation'))
    return "FaFilePowerpoint";
  if (fileType.startsWith('text/csv')) return "FaFileCsv";
  if (fileType.startsWith('audio/')) return "FaFileAudio";
  if (fileType.startsWith('video/')) return "FaFileVideo";
  return "FaFile"; // Default icon for generic files
};

export const handleFileUpload = async (
  files: FileList,
  setImageUrls: React.Dispatch<React.SetStateAction<string[]>>,
  setFileUrls: React.Dispatch<React.SetStateAction<{ name: string; url: string; icon: string }[]>>,
  setIsUploading: React.Dispatch<React.SetStateAction<boolean>>,
  conversationId: string,
  token: string
) => {
  setIsUploading(true);

  for (const file of Array.from(files)) {
    // console.log(file.type)
    if (file.type.toLowerCase().startsWith('image/svg') || file.name.toLowerCase().endsWith("svg")) {
      const pngFile = await convertSvgToPngFile(file);
      const imageUrl = await uploadFileToBlob(pngFile, token);
      setImageUrls((prevUrls) => [...prevUrls, imageUrl]);
    }
    else if (file.type.startsWith('image/')) {
      const imageUrl = await uploadFileToBlob(file, token);
      setImageUrls((prevUrls) => [...prevUrls, imageUrl]);
    } else {
      const fileUrl = await uploadFileToBlob(file, token);
      
      const fl = {
        name: file.name,
        url: fileUrl,
        icon: getFileIcon(file.type),
      };

      setFileUrls((prevFiles) => [...prevFiles, fl]);
    }
  }

  setIsUploading(false);
  // return attachmentUrls;
};