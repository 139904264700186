// frontend/src/components/LeftPane.tsx
import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { renameConversation, addConversation, deleteConversation } from '../chatReducer';
import { FaEllipsisV, FaEdit } from 'react-icons/fa';


const LeftPane: React.FC<{
  conversations: Array<{ id: string; title: string }>;
  onSelectConversation: (id: string) => void;
  onNewConversation: () => void;
}> = ({ conversations, onSelectConversation, onNewConversation }) => {
  const dispatch = useDispatch();
  const selectedConversationId = useSelector((state: any) => state.chat.selectedConversationId);
  const [editingId, setEditingId] = useState<string | null>(null);
  const [newTitle, setNewTitle] = useState<string>('');
  const [contextMenuId, setContextMenuId] = useState<string | null>(null);
  const [hoveredConversationId, setHoveredConversationId] = useState<string | null>(null);
  const newConversationButtonRef = useRef<HTMLButtonElement>(null);
  const contextMenuRef = useRef<HTMLDivElement>(null);

  const handleRename = (conversationId: string) => {
    if (newTitle.trim()) {
      dispatch(renameConversation({ conversationId, newTitle }));
      setEditingId(null);
      setNewTitle('');
    }
  };

  const handleDelete = (conversationId: string) => {
    if (window.confirm("Are you sure you want to delete this conversation? This action is irreversible.")) {
      dispatch(deleteConversation(conversationId));
    }
  };

  const handleNewConversation = () => {
    const findMaxValue = (data: any[]) => {
      return Math.max(...data.map(item => item.id));
    };
  
    const maxValue = findMaxValue(conversations);

    const newId = (maxValue + 1).toString();
    const newConversation = { id: newId, title: 'New chat', messages: [] };
    dispatch(addConversation(newConversation));
  };

  const handleClickOutside = (event: MouseEvent | TouchEvent) => {
    if (
      contextMenuRef.current &&
      !contextMenuRef.current.contains(event.target as Node) &&
      newConversationButtonRef.current &&
      !newConversationButtonRef.current.contains(event.target as Node)
    ) {
      setContextMenuId(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  return (
    <div className="left-pane-content">
      <div className="new-conversation-button-container">
        <button
          onClick={handleNewConversation}
          ref={newConversationButtonRef}
        >
          <FaEdit style={{ color: 'gray', fontSize: '32px' }}/>
        </button>
      </div>
      <div className="conversations-list text-sm">
        {conversations.map(conversation => (
          <div
            key={conversation.id}
            className={`conversation-item ${selectedConversationId === conversation.id ? 'selected' : ''}`}
            onClick={() => onSelectConversation(conversation.id)}
            onMouseEnter={() => setHoveredConversationId(conversation.id)}
            onMouseLeave={() => setHoveredConversationId(null)}
          >
            {editingId === conversation.id ? (
              <input
                type="text"
                value={newTitle}
                onChange={e => setNewTitle(e.target.value)}
                onBlur={() => setEditingId(null)}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    handleRename(conversation.id);
                  }
                }}
                autoFocus
              />
            ) : (
              <>
                <span style={{ flexGrow: 1, textAlign: 'left', marginLeft: '5px' }}>{conversation.title}</span>
                {(hoveredConversationId === conversation.id || selectedConversationId === conversation.id) && (
                  <FaEllipsisV
                    onClick={e => {
                      e.stopPropagation();
                      setContextMenuId(contextMenuId === conversation.id ? null : conversation.id);
                    }}
                    style={{ marginLeft: '5px', marginRight: '5px', cursor: 'pointer' }}
                  />
                )}
                {contextMenuId === conversation.id && (
                  <div
                    ref={contextMenuRef}
                    className="context-menu"
                  >
                    <div
                      className="context-menu-item"
                      onClick={e => {
                        e.stopPropagation();
                        setEditingId(conversation.id);
                        setNewTitle(conversation.title);
                        setContextMenuId(null);
                      }}
                    >
                      Rename Chat
                    </div>
                    <div
                      className="context-menu-item"
                      onClick={e => {
                        e.stopPropagation();
                        handleDelete(conversation.id);
                        setContextMenuId(null);
                      }}
                    >
                      Delete Chat
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default LeftPane;
