// frontend/src/components/ChatDisclaimer.tsx
import React from 'react';

const ChatDisclaimer: React.FC = () => {
  return (
    <div className="chat-disclaimer">
      <span>ChatGPT can make mistakes. Check important info.</span>
    </div>
  );
};

export default ChatDisclaimer;
