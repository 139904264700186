// frontend/src/utils/exportUtils.ts
import { saveAs } from 'file-saver';
import { Document, Packer, Paragraph, TextRun, ImageRun, ExternalHyperlink, Table, TableCell, TableRow, FileChild } from 'docx';
import { Message } from '../types';

export async function getImageAsBase64(url: string): Promise<string> {
  const response = await fetch(url);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64data = reader.result as string;
      resolve(base64data.split(",")[1]);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

export   function parseHighlightedCode(element: HTMLElement): TextRun[] {
    const tokens: TextRun[] = [];
  
    Array.from(element.childNodes).forEach((child) => {
      if (child.nodeType === Node.TEXT_NODE) {
        // Split text node content by \n to handle line breaks
        const lines = child.textContent?.split("\n") || [];
        lines.forEach((line, index) => {
          tokens.push(new TextRun({ text: line, font: "Courier New", size: 20 }));
          if (index < lines.length - 1) {
            // Add a line break after each line except the last one
            tokens.push(new TextRun({ text: "", break: 1 }));
          }
        });
      } else if (child.nodeType === Node.ELEMENT_NODE) {
        const elementNode = child as HTMLElement;
        const style = elementNode.getAttribute("style");
  
        // Extract color from inline style if it exists
        let color = "f8f8f2"; // Default color
        if (style) {
          const colorMatch = style.match(/color:\s*(#[a-fA-F0-9]{6}|rgb\(\d+,\s*\d+,\s*\d+\));?/);
          if (colorMatch) {
            const rgbMatch = colorMatch[1].match(/rgb\((\d+),\s*(\d+),\s*(\d+)\)/);
            color = rgbMatch
              ? `#${parseInt(rgbMatch[1]).toString(16).padStart(2, "0")}${parseInt(rgbMatch[2])
                  .toString(16)
                  .padStart(2, "0")}${parseInt(rgbMatch[3]).toString(16).padStart(2, "0")}`
              : colorMatch[1].replace("#", ""); // Remove "#" for Word color format
          }
        }
  
        // Split the inner text of each element node on \n
        const elementTextLines = elementNode.innerText.split("\n");
        elementTextLines.forEach((line, index) => {
          tokens.push(new TextRun({
            text: line,
            color: color,
            font: "Courier New",
            size: 20,
          }));
          if (index < elementTextLines.length - 1) {
            tokens.push(new TextRun({ text: "", break: 1 })); // Add a line break after each line
          }
        });
      }
    });
  
    return tokens;
  }

export async function exportToWord(elementId: string): Promise<void> {
    const button = document.getElementById('exportToWordButton') as HTMLButtonElement | null;
    if(button) { button.disabled = true; }

    try {
        const element = document.getElementById(elementId) as HTMLElement | null;
        if (!element) {
        console.error(`Element with ID "${elementId}" not found.`);
        return;
        }

        const children: FileChild[] = [];
        let numberingCounter = 1;
        const listStack: { type: "ul" | "ol"; level: number; reference: string }[] = [];
        let lastListType: "ul" | "ol" | null = null;
        let lastLevel = 0;

        async function processNode(node: Node, level = 0) {
        if (node.nodeType === Node.TEXT_NODE) {
            children.push(
            new Paragraph({
                children: [ new TextRun(node.textContent?.trim() || "")],
                spacing: { after: 100 },
            })
            );
        } else if (node.nodeType === Node.ELEMENT_NODE) {
            const el = node as HTMLElement;

            if (el.tagName.toLowerCase() === "button") {
            return;
            }

            if (el.tagName?.toLowerCase() === "img") {
            const img = el as HTMLImageElement;
            const imageSrc = img.getAttribute("src");

            if (imageSrc) {
                const base64 = await getImageAsBase64(imageSrc);
                const image = new ImageRun({
                data: base64,
                transformation: {
                    width: img.width || 100,
                    height: img.height || 100,
                },
                type: 'png'
                });
                children.push(new Paragraph({ children: [image] }));
            }
            } else if (el.tagName.toLowerCase() === "code") {
            const highlightedRuns = parseHighlightedCode(el);
            children.push(
                new Paragraph({
                children: highlightedRuns,
                shading: { fill: "000000" },
                border: {
                    top: { color: "000000", space: 1, size: 6, style: "single" },
                    bottom: { color: "000000", space: 1, size: 6, style: "single" },
                    left: { color: "000000", space: 1, size: 6, style: "single" },
                    right: { color: "000000", space: 1, size: 6, style: "single" },
                },
                spacing: { after: 200 },
                })
            );
            } else if (el.tagName.toLowerCase() === "a") {
            const url = el.getAttribute("href");
            const linkText = el.innerText || "";

            if (url) {
                const hyperlink = new ExternalHyperlink({
                link: url,
                children: [new TextRun({ text: linkText, style: "Hyperlink" })],
                });
                children[children.length -1].addChildElement(hyperlink);
            }
            } else if (el.tagName.toLowerCase() === "table") {
            const wordTable = await processTable(el);
            children.push(wordTable);
            } else if (el.tagName.toLowerCase() === "ul" || el.tagName.toLowerCase() === "ol") {
            const isOrdered = el.tagName.toLowerCase() === "ol";
            const listType = isOrdered ? "ol" : "ul";

            const isNewList = level === 0 && (lastListType !== listType || lastLevel !== 0);
            const reference = isOrdered
                ? isNewList
                ? `ordered-list-${numberingCounter++}`
                : listStack[listStack.length - 1]?.reference || `ordered-list-${numberingCounter - 1}`
                : "unordered-list";

            if (isNewList) {
                lastListType = listType;
                lastLevel = 0;
            }

            listStack.push({ type: listType, level, reference });

            Array.from(el.children).forEach((li) => {
                if (li.tagName.toLowerCase() === "li") {
                const listItemParagraph = new Paragraph({
                    children: [new TextRun(li.textContent?.trim() || "")],
                    bullet: !isOrdered ? { level } : undefined,
                    numbering: isOrdered ? { reference, level } : undefined,
                });
                children.push(listItemParagraph);

                Array.from(li.children).forEach((nested) => {
                    if (nested.tagName.toLowerCase() === "ul" || nested.tagName.toLowerCase() === "ol") {
                    processNode(nested, level + 1);
                    }
                });
                }
            });

            listStack.pop();
            } else {
            for (const child of Array.from(el.childNodes)) {
                await processNode(child);
            }
            }
        }
        }

        async function processTable(tableEl: HTMLElement): Promise<Table> {
        const rows: TableRow[] = [];

        Array.from(tableEl.getElementsByTagName("tr")).forEach((tr) => {
            const cells: TableCell[] = [];
            Array.from(tr.children).forEach((cell) => {
            if (cell.tagName.toLowerCase() === "th") {
                const cellText = cell.textContent?.trim() || "";
                const cellContent = new Paragraph({
                children: [
                    new TextRun({
                    text: cellText,
                    color: "ffffff",
                    }),
                ],
                });
            
                cells.push(
                new TableCell({
                    children: [cellContent],
                    shading: { fill: "686868" },
                })
                );
            }
            if (cell.tagName.toLowerCase() === "td") {
                const cellContent = new Paragraph(cell.textContent?.trim() || "");
                cells.push(new TableCell({ children: [cellContent] }));
            }
            });
            rows.push(new TableRow({ children: cells }));
        });

        return new Table({
            rows: rows,
            width: { size: 100, type: "pct" },
            borders: {
            top: { style: "single", size: 1, color: "000000" },
            bottom: { style: "single", size: 1, color: "000000" },
            left: { style: "single", size: 1, color: "000000" },
            right: { style: "single", size: 1, color: "000000" },
            insideHorizontal: { style: "single", size: 1, color: "000000" },
            insideVertical: { style: "single", size: 1, color: "000000" },
            },
        });
        }

        await processNode(element);

        const numberingConfig = [
        {
            reference: "unordered-list",
            levels: [
            {
                level: 0,
                format: "bullet" as const,
                text: "•",
                alignment: "left" as const,
            },
            {
                level: 1,
                format: "bullet" as const,
                text: "◦",
                alignment: "left" as const,
            },
            {
                level: 2,
                format: "bullet" as const,
                text: "▪",
                alignment: "left" as const,
            },
            ],
        },
        ...Array.from({ length: numberingCounter - 1 }, (_, i) => ({
            reference: `ordered-list-${i + 1}`,
            levels: [
            {
                level: 0,
                format: "decimal" as const,
                text: "%1.",
                alignment: "left" as const,
            },
            {
                level: 1,
                format: "decimal" as const,
                text: "%2.",
                alignment: "left" as const,
            },
            {
                level: 2,
                format: "decimal" as const,
                text: "%3.",
                alignment: "left" as const,
            },
            ],
        })),
        ] as const;

        const doc = new Document({
        styles: {
            paragraphStyles: [
            {
                id: "Normal",
                name: "Normal",
                run: {
                font: "Verdana",
                size: 20,
                },
            },
            ],
        },
        numbering: {
            config: numberingConfig,
        },
        sections: [
            {
            properties: {},
            children: children,
            },
        ],
        });

        const blob = await Packer.toBlob(doc);
        saveAs(blob, "document.docx");
    } catch (error) {
        console.error(error)
    }

    if(button) { button.disabled = false; }
    }

    export const exportToMarkdown = (messages: Message[], conversationId: string) => {
        // Iterate through each message and its content
        const markdownContent = messages.map(message => {
          return message.content.map((msgContent) => {
            switch (msgContent.type) {
              case 'text':
                return `### Message\n\n${msgContent.text}\n`;
              case 'file':
                return `### File: [${msgContent.file.name}](${msgContent.file.url})\n`;
              case 'image_url':
                return `### Image: ![Image](${msgContent.image_url.url})\n`;
              default:
                return ''; // Handle unknown content types
            }
          }).join('\n'); // Join each content item for the current message
        }).join('\n\n'); // Join each message's markdown content
      
        // Create a Blob and trigger a download of the Markdown file
        const blob = new Blob([markdownContent], { type: 'text/markdown' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `conversation_${conversationId}.md`;
        link.click();
        URL.revokeObjectURL(url);
      };
      