// frontend/src/hooks/usePasteHandler.ts
import { useEffect, ClipboardEvent, Dispatch } from 'react';
import { handleFileUpload } from '../utils/fileUtils';
import React from 'react';
import useAuth from './useAuth';

const usePasteHandler = (
  inputRef: React.RefObject<HTMLTextAreaElement>,
  conversationId: string,
  setImageUrls: React.Dispatch<React.SetStateAction<string[]>>,
  setFileUrls: React.Dispatch<React.SetStateAction<{ name: string; url: string; icon: string }[]>>,
  setIsUploading: React.Dispatch<React.SetStateAction<boolean>>,
  dispatch: Dispatch<any>
) => {
  const { token } = useAuth();

  const handlePaste = async (event: ClipboardEvent) => {
    const clipboardData = event.clipboardData;
    const items = clipboardData.items;
  
    // Check if the clipboard contains RTF data
    const rtfItem = Array.from(items).find(item => item.type === 'text/rtf');
    const txtItem = Array.from(items).find(item => item.type === 'text/plain');
  
    // If RTF content is detected, extract and paste the plain text
    if (rtfItem && txtItem) {
      event.preventDefault(); // Prevent default paste behavior
      txtItem.getAsString(text => {
        if(inputRef?.current) {
          inputRef.current.value += text
        }
      });
      return;  // Skip further processing as we're only handling the plain text
    }
  
    // Process file or image upload if no RTF content is found
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
  
      // Allow file upload (including images) except for PNG when RTF content is present
      if (item.kind === 'file') {
        event.preventDefault();
        const file = item.getAsFile();
        if (file && token) {
          const dt = new DataTransfer();
          dt.items.add(file);
          await handleFileUpload(dt.files, setImageUrls, setFileUrls, setIsUploading, conversationId, token);
        }
      }
    }
  };

  useEffect(() => {
    const handlePasteEvent = (event: ClipboardEvent) => handlePaste(event);
    document.addEventListener('paste', handlePasteEvent as unknown as EventListener);

    return () => {
      document.removeEventListener('paste', handlePasteEvent as unknown as EventListener);
    };
  }, [conversationId, dispatch]);

  return {};
};

export default usePasteHandler;
