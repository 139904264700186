// frontend/src/components/DragOverlay.tsx
import React from 'react';

interface DragOverlayProps {
  isDragging: boolean;
}

const DragOverlay: React.FC<DragOverlayProps> = ({ isDragging }) => {
  if (!isDragging) return null;

  return (
    <div className="drag-overlay">
      <div className="drag-text">Drag and drop files here</div>
    </div>
  );
};

export default DragOverlay;
