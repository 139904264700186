// frontend/src/components/ScrollToBottomButton.tsx
import React from 'react';
import { FaArrowCircleDown } from 'react-icons/fa';

const ScrollToBottomButton = ({ containerRef, visible }: { containerRef: any, visible: boolean }) => {
  const scrollToBottom = () => {
    if (containerRef.current) {
      console.log('scrollToBottom')
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };

  return (
    <div className="scroll-to-bottom-button-container">
      <FaArrowCircleDown 
        onClick={scrollToBottom}
        className='scroll-to-bottom-button'
        style={{ cursor: 'pointer', fontSize: '24px', visibility: visible ? "visible" : "hidden" }}
        title="Scroll to bottom"
      />
    </div>
  );
};

export default ScrollToBottomButton;