// frontend/src/components/ImagePreview.tsx
import React from 'react';
import { IconBaseProps } from 'react-icons';

import {
  FaFileWord,
  FaFileAudio,
  FaFileExcel,
  FaFilePowerpoint,
  FaFilePdf,
  FaFileVideo,
  FaFileCsv,
  FaFile
} from 'react-icons/fa';

interface ImagePreviewProps {
  imageUrls: string[] | null;
  fileUrls: { name: string; url: string; icon: string }[] | null;
}

const iconMap: { [key: string]: React.ComponentType<IconBaseProps> } = {
  FaFilePdf,
  FaFileWord,
  FaFileExcel,
  FaFilePowerpoint,
  FaFileCsv,
  FaFileAudio,
  FaFileVideo,
  FaFile,
};

const ImagePreview: React.FC<ImagePreviewProps> = ({ imageUrls, fileUrls }) => {
  if ((!imageUrls || imageUrls.length === 0) && (!fileUrls || fileUrls.length === 0)) return null;

  return (
    <div className="image-preview-container">
      {imageUrls?.map((imageUrl) => (
        <img
          key={imageUrl}
          src={imageUrl}
          alt="Preview"
          style={{ width: '56px', height: '56px', objectFit: 'scale-down', border: '1px solid gray', borderRadius: '5px' }}
        />
      ))}

      {fileUrls?.map((file) => {
        const IconComponent = iconMap[file.icon] || FaFile; // Default to FaFile if icon not found
        return (
          <IconComponent key={file.url} size={56} />
        );
      })}
    </div>
  );
};

export default ImagePreview;