import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { clearStreamingText } from '../chatReducer';

Modal.setAppElement('#root');

interface SessionTimeoutModalProps {
  isSending: boolean;
  streamingText: string;
}

const SessionTimeoutModal: React.FC<SessionTimeoutModalProps> = ({ isSending, streamingText }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setTimeout(() => {
      const interval = setInterval(() => {
        if (!isSending && streamingText === '') {
          setIsModalOpen(true);
          dispatch(clearStreamingText('all')); // Clear streaming text on timeout
          clearInterval(interval);
        }
      }, 1000);

      return () => clearInterval(interval);
    }, 59 * 60 * 1000);

    return () => clearTimeout(timer);
  }, [isSending, streamingText, dispatch]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleReload = () => {
    window.location.reload();
  };

  return (
    <Modal 
      isOpen={isModalOpen}
      onRequestClose={handleCloseModal}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      contentLabel="Session Timeout Alert"
      className="ModalContent"
      overlayClassName="ModalOverlay"
    >
      <h2 className="ModalHeader">Session Expired</h2>
      <button className="ReloadButton" onClick={handleReload}>Reload Page</button>
    </Modal>
  );
};

export default SessionTimeoutModal;
